const axios = require('axios').default;

function createModal({ announcement_text, button_url, button_text, image_url }) {
  const element = `
  <div aria-hidden="true" class="modal micromodal-slide" id="announcement-modal">
    <div class="modal__overlay z-max" data-micromodal-close="" tabindex="-1">
      <div aria-modal="true" class="modal__container w-50 w-90" role="dialog">
        <main class="modal__content" id="announcement-modal-content">
          <div class="flex flex-column flex-row-ns justify-start-ns tc tl-ns">
            <div class="content-w">
              <img src="${image_url}" class="w-100 h-100" alt="Image">
              </div>
            <div class="w50-ns pl2 pr3 tc f4 content-w outer_align">
              <div class="justify-center inner_align">
                ${announcement_text}
                <div class="mt3"></div>
                <a href="http://${button_url}" class="link ma2 pa1 dib white bg-animate hover-bg-blue bn f6 br2 b no-underline align-right" style="background-color: #30aea8">${button_text}</a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
  `;
  return element;
}

async function fetchAnnouncements() {
  const url = 'https://conl.mx/api/announcements';;
  const announcements = document.querySelector('.announce_modal');
  try {
    const { data } = await axios.get(url, {
      'Access-Control-Allow-Origin':  '',
      headers: { Authorization: 'Bearer aG9ARiB2WNeFOPAFYUAY' }
    });
    if (data != null) {
      const content = createModal(data);
      announcements.insertAdjacentHTML('beforebegin', content);

      MicroModal.show('announcement-modal');
      var d = new Date();
      d.setTime(d.getTime() + (6*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = "ads=off; "+expires+"; path=/";
    }
    return data;
  } catch(e) {
    console.log(e);
    return 0;
  }
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

window.onload = async function checkModal() {
  if (getCookie("ads") != "off") {
    MicroModal.init();
    const announce = await fetchAnnouncements();
  }
}
